<template class="text_google">
 <v-app>
    <v-system-bar class="noprint" color="grey darken-2" dark height="40" app lights-out>
      <v-toolbar-items class="ml-5">
        <v-btn href="javascript:window.print()" class="title elevation-0 mr-2" color="grey darken-1" x-large>
          <v-icon>mdi-printer</v-icon>&nbsp; พิมพ์
        </v-btn>
        <v-btn @click.stop="Export2Doc('exportContent','word-content')" class="title elevation-0" color="grey darken-1" x-large>
          <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>
     <div id="exportContent" style="mso-page-orientation: landscape;">
    <div class="page">
      <div align="center">
          <v-img max-height="30mm" max-width="25mm" src="ovec.png"></v-img>
       <div class="head"> แบบรายงานข้อมูลอัตรากำลังสถานศึกษา ประจำปี {{ yearsReport }}</div>     
       <div class="head"> {{ rate_work_g_query.college_name }}</div>     
     <div>___________________________</div>
      </div>
      {{url_result}}  
    <br>
      <table>
                                          <thead>
                                            <tr>
                                              <th
                                                class="text-center"
                                                width="30%"
                                              >
                                                ประเภทอัตรา
                                              </th>
                                              <th
                                                class="text-center"
                                                width="10%"
                                              >
                                                มีจริง
                                              </th>
                                              <th
                                                class="text-center grey lighten-1"
                                                width="15%"
                                              >
                                                จำนวนห้องเรียน
                                              </th>
                                              <th
                                                class="text-center"
                                                width="10%"
                                              >
                                                เกณฑ์
                                              </th>
                                              <th
                                                class="text-center grey lighten-1"
                                                width="15%"
                                              >
                                                อัตรากำลังรวม
                                              </th>
                                              <th
                                                class="text-center"
                                                width="20%"
                                              >
                                                (-ขาด/+เกิน)
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>ผอ.สถานศึกษา</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_dr
                                                }}
                                              </td>
                                              <td
                                                class="text-center grey lighten-1"
                                                rowspan="2"
                                              >
                                              {{ rate_work_g_query.rate_room_cal_all }}
                                              </td>
                                              <td class="text-center ">
                                                {{
                                                  rate_work_g_query.rate_criterion_dr
                                                }}
                                              </td>
                                              <td
                                                class="text-center grey lighten-1"
                                                rowspan="2"
                                              >
                                              {{ rate_work_g_query.rate_man_power_teach_all }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_criterion_incomplete_dr
                                                }}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>รอง ผอ.สถานศึกษา</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sedr
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_criterion_sedr
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_criterion_incomplete_sedr
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                            <tr>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                              >
                                                ครูผู้สอน (รวม)
                                              </td>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_teach_official_all
                                                }}
                                              </td>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_teach_civil_all
                                                }}
                                              </td>
                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_teach_all
                                                }}
                                              </td>
                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_teach_alls
                                                }}
                                              </td>
                                              <td
                                                class="text-center blue darken-1 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_incomplete_teach_all
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td></td>
                                              <td class="text-center">
                                                ข้าราชการ
                                              </td>
                                              <td class="text-center">
                                                พนักงานราชการ
                                              </td>
                                              <td class="text-center">
                                                ข้าราชการ
                                              </td>
                                              <td class="text-center">
                                                พนักงานราชการ
                                              </td>
                                              <td class="text-center"></td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาสามัญ</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_gs_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาอุตสาหกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_is_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาพาณิชยกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_cm_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาศิลปกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_art_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาคหกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_em_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาเกษตรกรรม</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_ag_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>-ประเภทวิชาประมง</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_fish_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                -ประเภทวิชาอุตสาหกรรมการท่องเที่ยว
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_tour_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                -ประเภทวิชาอุตสาหกรรมสิ่งทอ
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_textile_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                -ประเภทวิชาเทคโนโลยีสารสนเทศ
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_insy_incomplete
                                                }}
                                              </td>
                                            </tr>

                                                      <tr>
                                              <td>
                                                -ประเภทวิชาอุตสาหกรรมบันเทิงและดนตรี
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_entertain_incomplete
                                                }}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                -ประเภทวิชาพาณิชยนาวี
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_navy_incomplete
                                                }}
                                              </td>
                                            </tr>


            <tr>
                                              <td>
                                                -ประเภทวิชาเทคโนโลยีและนวัตกรรมเกษตรอุตสาหกรรม
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_agriInno_incomplete
                                                }}
                                              </td>
                                            </tr>

                                            
                                            <tr>
                                              <td>-หลักสูตรระยะสั้น*</td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sc_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td class="bold">
                                                <strong
                                                  >บุคลากรสนับสนุนการสอน</strong
                                                >
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_ta
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_tb
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_ta_official
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_tb_civil
                                                }}
                                              </td>
                                              <td class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_sup_incomplete
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                              >
                                                <h3>รวมอัตรา</h3>
                                              </td>
                                              <td
                                                class="text-center green lighten-1 white--text"
                                                colspan="2"
                                              >
                                                <h3>
                                                  {{
                                                    rate_work_g_query.rate_man_power_all
                                                  }}
                                                </h3>
                                              </td>

                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_all
                                                }}
                                              </td>
                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              ></td>
                                              <td
                                                class="text-center cyan lighten-2 white--text"
                                              >
                                                {{
                                                  rate_work_g_query.rate_criterion_incomplete_all
                                                }}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td></td>                                             
                                              <td colspan="2" class="text-center">
                                              ลูกจ้างประจำ
                                              </td>
                                              <td colspan="2" class="text-center">
                                             ลูกจ้างชั่วคราว(ครูจ้างสอน)
                                              </td>
                                              <td class="text-center">ลูกจ้างชั่วคราว(สายสนับสนุน,เจ้าหน้าที่) </td>                                             
                                            </tr>

                                            <tr>
                                              <td class="bold">
                                                <strong
                                                  >ลูกจ้างประจำ/ชั่วคราว</strong
                                                >
                                              </td>
                                              <td colspan="2" class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_pte_ta
                                                }}
                                              </td>
                                              <td colspan="2" class="text-center">
                                                {{
                                                  rate_work_g_query.rate_work_g_pte_tc
                                                }}
                                              </td>
                                              <td class="text-center">  {{
                                                  rate_work_g_query.rate_work_g_pte_tb
                                                }}</td>
                                              <td class="text-center"></td>
                                              <td class="text-center"></td>
                                            </tr>
                                          </tbody>
                                        </table>             
                <br><br>
          <table border="0" width="100%" align="center">        
                  <tr>            
             <td width="60%"></td>             
             <td width="40%"><div class="regular16">ลงชื่อ.............................................................</div></td>             
         </tr> 
          <tr>            
             <td width="60%"></td>             
             <td width="40%" align="center"><div class="regular16">(.................................................)</div></td>             
         </tr> 
          <tr>            
             <td width="60%"></td>             
             <td width="40%" align="center"><div class="regular16">ผู้อำนวยการ{{ rate_work_g_query.college_name }}</div></td>             
         </tr> 
          </table>
          <!--  {{ id_ref_url }} -->
    </div>
  </div>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
     data: () => ({
           ApiKey: 'HRvec2021',        
       rate_work_g_query: [],
    }),

    async mounted() {  
  
 await this.rate_work_gQueryAll()
    
    },

     methods: {     
           
    
    async rate_work_gQueryAll() {   
   
      let result = await this.$http
        .post("rate_work_g.php", {
          ApiKey: this.ApiKey,
          rate_work_g_college_code: this.collegeCode,
          rate_work_g_year: this.yearsReport
        })     
      this.rate_work_g_query = result.data;    
    },

  



      Export2Doc(element, filename) {
        var preHtml =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>"
        var postHtml = "</body></html>"
        var html = preHtml + document.getElementById(element).innerHTML + postHtml

        var blob = new Blob(['\ufeff', html], {
          type: 'application/msword'
        })

        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html)

        // Specify file name
        filename = filename ? filename + '.doc' : 'document.doc'

        // Create download link element
        var downloadLink = document.createElement("a")

        document.body.appendChild(downloadLink)

        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          // Create a link to the file
          downloadLink.href = url

          // Setting the file name
          downloadLink.download = filename

          //triggering the function
          downloadLink.click()
        }
        document.body.removeChild(downloadLink)
      },     
    },
    props: {
      source: String
    },


    computed:{    

    yearsReport(){
    let result =window.location.href;    
    let split_s = result.split('/')
    return split_s[6]
    },

    collegeCode(){
        let result =window.location.href;    
    let split_s = result.split('/')
    return split_s[7]
    },

    
    },


   
  
  }
</script>
<style>
@import url(https://fonts.googleapis.com/css?family=Prompt:400,300|Roboto:400,300&subset=latin,thai);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1.5cm;
  padding-bottom: 1.75cm;
  padding-left: 2cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
    height: 247mm;
  }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
        border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
      font-size: 18px;
      font-weight: bold;
    }
    .regular12 {
      font-size: 12px;
    }
    .blod12 {
      font-size: 12px;
      font-weight: bold;
    }
    .blackRegula8 {
      font-size: 8px;
    } */
  .noprint {
    display: none;
  }
}
</style>
